<template>
  <div>
    <Menu />
    <div id="down">
      <!-- crumbs -->
      <div class="crumbs">
        <div class="content d-flex d-flex-middle">
          <p class="color9 fontSize12">当前所在位置：</p>
          <router-link to="/" class="color9 fontSize12">首页</router-link>
          <p class="color9 fontSize12 and">></p>
          <p class="color fontSize12">热门真题</p>
        </div>
      </div>
      <!-- classify -->
      <div class="classify">
        <div class="content classifyList bg_fff">
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">行业分类</p>
            <div class="classification d-flex d-flex-middle d-flex-wrap">
              <p :class="{active:industryActive==index}" v-for="(item,index) in industryList" :key="index" @click="changeIndustryActive(item.Industry_Id,index)">{{item.Industry_Title}}</p>
            </div>
          </div>
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">考试分类</p>
            <div class="cl_list">
              <div class="cl_li" v-show="industryActive==index" v-for="(item,index) in industryList" :key="index">
                <div class="classifyLi_list d-flex d-flex-middle d-flex-wrap">
                  <p :class="{active:industryChilActive==indexClil}" v-for="(itemClil,indexClil) in item.chil" :key="indexClil" @click="changeIndustryChilActive(itemClil.Industry_Id,indexClil)">{{itemClil.Industry_Title}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">上传时间</p>
            <div class="classifyLi_list type d-flex d-flex-middle d-flex-wrap">
              <p :class="{active:vuesDictionActive==-1}" @click="changeCourseType(-1,-1)">全部</p>
              <p v-for="(item,index) in vuesDictionaryList" :key="index" :class="{active:vuesDictionActive==index}" @click="changeCourseType(item.key,index)">{{item.value}}</p>
            </div>
          </div>

        </div>
      </div>
      <!-- list -->
      <div class="list content bg_fff">
        <div class="_li d-flex d-flex-middle d-flex-between" v-for="(item,index) in listData" :key="index">
          <div class="d-flex d-flex-middle">
            <img src="../assets/images/file.png" class="li_imgs" />
            <div class="liInfo">
              <p class="li_title">{{item.exercisesPaper_Title}}</p>
              <div class="d-flex d-flex-middle">
                <div class="d-flex d-flex-middle">
                  <p class="color9 fontSize12">大小：</p>
                  <p class="color3 fontSize12">{{item.exercisesPaper_Size}}</p>
                </div>
                <div class="d-flex d-flex-middle">
                  <p class="color9 fontSize12">时间：</p>
                  <p class="color3 fontSize12">{{item.yearsCategory}}</p>
                </div>
                <div class="d-flex d-flex-middle">
                  <p class="color9 fontSize12">类型：</p>
                  <p class="color3 fontSize12">{{item.exercisesPaper_Ext}}</p>
                </div>
              </div>
            </div>
          </div>

          <a @click="handDown(item)" class="d-flex d-flex-middle d-flex-center download">
            <span>免费下载</span>
            <img src="../assets/images/download.png" />
          </a>
        </div>

        <div class="none" v-if="listData.length==0">
          <img src="../assets/images/none.png"/>
        </div>
      </div>
      <!--  -->
      
      <!--  -->

      <div style="text-align: center; margin-top: 60px;margin-bottom: 60px;">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total" :page-size="8">
        </el-pagination>
      </div>
    </div>
    <Foot />
    <RightGuide />

  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";

import { industry, vuesDictionaryByKey, splitListPaper } from "@/api/app"
import { downloadByBlob } from "@/utils/index.js"
export default {
  name: "Home",
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
      vuesDictionaryList: [],	//时间分类 
      vuesDictionActive: -1,	//课程分类 active
      industryActive: 0,	//所有分类数据active 一级分类 
      industryChilActive: -1,	//所有分类数据active 二级分类
      industryList: [],// 所有分类数据，对应页面行业分类用一级分类，考试分类用二级分类
      listData: [],		//数据列表
      total: 0,//总页数
    };
  },
  mounted() {
    this.getVuesDictionaryByKey()
  },

  methods: {

    handDown(item) {
      //截取文件格式
      let itemUrl = item.exercisesPaper_Path
      let index = itemUrl.lastIndexOf('.')
      let str = itemUrl.substring(index + 1, itemUrl.length)

      const type = 'image/jpeg/png/gif'

      if (type.includes(str)) {
        console.log(str)
        downloadByBlob((this.$store.state.httpUrl + item.exercisesPaper_Path), item.exercisesPaper_Title)
      }
      else {
        window.location = this.$store.state.httpUrl + item.exercisesPaper_Path
      }
    },


    //点击课程分类
    changeCourseType(id, index) {
      this.vuesDictionActive = index
      this.getExercises()
    },

    //点击行业分类
    changeIndustryActive(id, index) {
      this.industryChilActive = -1
      this.industryActive = index
      this.getExercises()
    },
    //点击考试分类
    changeIndustryChilActive(id, index) {
      this.industryChilActive = index
      this.getExercises()
    },

    //分类
    getVuesDictionaryByKey() {
      const params = { dicNos: 'YearsCategory' }
      vuesDictionaryByKey(params).then((res) => {
        if (res) {
          const data = res[0].data
          this.vuesDictionaryList = data
          //获取全部分类
          this.getIndustry()
        }
      })
    },

    //全部分类
    getIndustry() {
      const params = {}
      industry(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.industryList = data

          //获取列表数据
          this.getExercises()
        }
      })
    },

    //分页
    handleCurrentChange(page) {
      this.getExercises(page)
    },

    //数据列表
    getExercises(page) {
      const params = {
        industry_Id: '',
        yearsCategory: '2017',
        val: '',
        page: page || 1,
      }

      //判断是否选中考试（二级）分类, 二级选中就传二级,反之传一级
      if (this.industryChilActive == -1) {
        params.industry_Id = this.industryList[this.industryActive].Industry_Id
      } else {
        params.industry_Id = this.industryList[this.industryActive].chil[this.industryChilActive].Industry_Id
      }

     // 时间分类 选择全部 就传空
      if (this.vuesDictionActive == -1) {
        params.yearsCategory = ""
      }else {
        params.yearsCategory = this.vuesDictionaryList[this.vuesDictionActive].key
      }

      splitListPaper(params).then((res) => {
        if (res.status) {
          const data = res.data

          this.listData = data.listCourse
          console.log(this.listData)
          this.total = data.totalCount
        }
      })

    },
  },
};
</script>

<style lang="scss">
/*  */
body {
  background-color: #fafafa;
}

#down {
  /*  */
  .classify {
    margin-bottom: 50px;
  }
  .classify .content {
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 10px;
  }
  .classifyLi {
    margin-bottom: 10px;
  }
  .classifyLi .classifyLi_title {
    width: 100px;
    line-height: 30px;
    color: #999;
    font-size: 14px;
    margin: 5px 0;
  }
  .classifyLi_list,
  .cl_list {
    flex: 1;
  }
  .classifyLi_list p,
  .classification p {
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
    color: #333;
    margin: 5px;
  }
  .classifyLi_list .active,
  .classification .active {
    background-color: #0087ed;
    color: #fff;
    border-radius: 5px;
  }
  .cl_li {
    // display: none;
  }
  .cl_li.show {
    display: block;
  }
  /*  */
  .list {
    box-sizing: border-box;
    padding: 30px;
    margin-bottom: 100px;
  }
  ._li {
    box-sizing: border-box;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    width: auto !important;
  }
  .li_imgs {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }
  .liInfo .li_title {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    max-width: 1000px;
    margin-bottom: 10px;
  }
  .liInfo div {
    margin-right: 30px;
  }
  .download {
    width: 130px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #0087ed;
    border-radius: 18px;
    color: #0087ed;
    cursor: pointer;
  }
  .download img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}
</style>